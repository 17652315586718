import moment from 'moment';

export const epochToFormat = (epoch: number, format: string, commaForMs = true) => {
    const value = commaForMs ? epoch : epoch / 1000;
    return moment.unix(value).format(format);
}

export const secondsToFormat = (seconds: number, format: string, tryExcludeHours = true) => {
    if (tryExcludeHours && format === 'HH:mm:ss' && seconds < 3600) {
        format = 'mm:ss'
    }

    return moment().startOf('day').seconds(seconds).format(format);
}