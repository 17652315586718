
import { PowerRecord, PursuitInput, PursuitResult, SimulationInput } from "../../types";
import { apiSlice } from "./apiSlice";

const simulationSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        simulate: builder.mutation<PowerRecord[], SimulationInput>({
            query: (input) => ({
                url: `/simulate`,
                method: 'POST',
                body: input,
            }),
        }),
        pursuit: builder.mutation<PursuitResult[], PursuitInput>({
            query: (input) => ({
                url: `/pursuit`,
                method: 'POST',
                body: input,
            }),
        }),
    }),
});

export const {
    useSimulateMutation,
    usePursuitMutation,
} = simulationSlice;