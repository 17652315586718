import { Container, styled } from '@mui/material';
import React from 'react';
import Footer from '../components/shared/Footer';
import Header from '../components/shared/Header';


interface LayoutProps {
    children: React.ReactNode
}

const StyledDiv = styled('div')(() => ({
    display: 'flex',
    minHeight: '74vh',
    flexDirection: 'column', 
    padding: 10,
}));

const Layout = ({ children }: LayoutProps) => (
    <>
        <Header />
        <StyledDiv>
            {children}
        </StyledDiv>
        <Footer />
    </>
)

export default Layout;