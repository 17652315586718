import { TextField } from "@mui/material";
import { useEffect, useRef } from "react";

const NumberTextField = (props: {
    value: number,
    id: string,
    label: string,
    errorMap: { [key: string]: boolean },
    setErrorMap: CallableFunction,
    onNewValue: CallableFunction,
    style?: React.CSSProperties,
}) => {
    const localValueRef = useRef('' + props.value);

    useEffect(() => {
        localValueRef.current = '' + props.value;
    }, [props.value])

    useEffect(() => {
        if (!props.errorMap[props.id]) {
            const newValue = Number(localValueRef.current);
            if (newValue !== props.value) {
                props.onNewValue(newValue);
            }
        }
    }, [localValueRef.current, props.errorMap])

    const changeNumberParam = (eventValue: string) => {
        props.setErrorMap({
            ...props.errorMap,
            [props.id]: isNaN(Number(eventValue)),
        });

        localValueRef.current = eventValue;
    }

    return (
        <TextField 
            id={props.id} 
            value={localValueRef.current}
            onChange={(e) => changeNumberParam(e.target.value)}
            autoComplete='off'
            label={props.label}
            error={props.errorMap[props.id]}
            helperText={props.errorMap[props.id] && 'Not a number'}
            sx={{ ...props.style }}
        />
    )
}

export default NumberTextField;