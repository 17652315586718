import React from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const FooterContainer = styled(Grid)(() => ({
    backgroundColor: '#1976d2',
    color: 'white',
    marginTop: 10,
    paddingBottom: 10,
}));

const Footer = () => (
    <FooterContainer container rowSpacing={2}>
        <Grid container item lg={12} justifyContent='center' direction='column' alignItems='center'>
                    <a href='mailto:robbe.decorte@ugent.be'><EmailIcon style={{ color: 'white' }} /></a>
            <Typography variant='body1' textAlign='center'>
                Developed by Robbe Decorte - IDLab's Sports Data Science team
            </Typography>
            <Typography variant='body1' textAlign='center'>
                Technologiepark-Zwijnaarde 122, 9052 Gent
            </Typography>
        </Grid>
        <Grid item lg={12} textAlign='center' columnGap={5}>
            <Box 
                component='img'
                src='https://sportsdatascience.be/assets/img/partners/ugent.png'
                sx={{ height: '50px', marginLeft: 1, marginRight: 1 }}
            />
            <Box 
                component='img'
                src='https://www.victoris.be/wp-content/uploads/2016/09/victoris-logo-neg.png'
                sx={{ height: '50px', marginLeft: 1, marginRight: 1 }}
            />
            <Box 
                component='img'
                src='https://sportsdatascience.be/assets/img/partners/imec.svg'
                sx={{ height: '40px', marginLeft: 1, marginRight: 1 }}
            />
        </Grid>
    </FooterContainer>
)

export default Footer;