import { FitRecord, RawPowerRecord } from "../types";
import { defined } from "./typecheck";

const fitDecoder = require('fit-decoder');

export const fitToJson = async (file: File) => {
    const buffer = await file.arrayBuffer();

    const jsonRaw = fitDecoder.fit2json(buffer);
    const json = fitDecoder.parseRecords(jsonRaw);

    const fitRecords: FitRecord[] = json.records;
    let processedValues: RawPowerRecord[] = fitRecords.filter(x => x.type === 'record' && defined(x.data.power) && x.data.power! < 10000).map(x => {
        const { timestamp, power, cadence } = x.data;
        return {
            date: new Date(timestamp).getTime(),
            pwr: power,
            cad: cadence,
        };
    }).sort((a: { date: number }, b: { date: number }) => a.date - b.date);

    // Fill missing seconds with the last known power value.
    for (let i = 0, init_length = processedValues.length; i < init_length - 1; ++i) {
        const timeDiff = processedValues[i + 1].date - processedValues[i].date;
        if (timeDiff !== 1000) {
            // console.log(i, timeDiff, processedValues[i].date, processedValues[i + 1].date);

            processedValues.push(...Array.from(Array((timeDiff / 1000) - 1).keys()).map(x => ({
                ...processedValues[i], 
                date: processedValues[i].date + (x + 1) * 1000 
            })));
        }
    }

    processedValues.sort((a, b) => a.date - b.date);
    return processedValues;
}