import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SimulationConfig } from "../types";

interface SettingsState extends SimulationConfig {
    chartBuckets: number,
}

const initialState: SettingsState = {
    startOffset: 0, // in milliseconds
    minRaceDuration: 250, // in seconds
    estimateDuration: true,
    chartBuckets: 300,
};

export const settingsSlice = createSlice({
    initialState,
    name: 'settings',
    reducers: {
        changeSimulationProp: (state, action: PayloadAction<{ option: string, value: number}>) => ({
            ...state,
            [action.payload.option]: action.payload.value,
        }),
        setChartBuckets: (state, action: PayloadAction<number>) => ({
            ...state,
            chartBuckets: action.payload,
        }),
    },
});


export const { changeSimulationProp, setChartBuckets } = settingsSlice.actions;

export const selectChartBuckets = (state: RootState) => state.settings.chartBuckets;
export const selectStartOffset = (state: RootState) => state.settings.startOffset;
export const selectMinRaceDuration = (state: RootState) => state.settings.minRaceDuration;
export const selectEstimateRaceDuration = (state: RootState) => state.settings.estimateDuration;
export const selectSimulationConfig = createSelector(
    [selectStartOffset, selectMinRaceDuration, selectEstimateRaceDuration], 
    (a, b, c): SimulationConfig => ({
        startOffset: a,
        minRaceDuration: b,
        estimateDuration: c,
    }),
);

export default settingsSlice.reducer;