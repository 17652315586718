import { Button, styled } from "@mui/material"
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ReferenceArea, ReferenceLine } from "recharts"
import { lineConfigs } from ".";
import { selectData } from "../../features/raceSlice";
import { epochToFormat, LTTB, secondsToFormat } from "../../helpers"
import { useAppSelector } from "../../hooks/redux";
import { DataIndices, PowerRecord, PursuitResult } from "../../types";

const initialLRConf = { left: 0, right: 0 };

const ChartContainer = styled('div')({
    padding: 10,
    margin: 10,
})

interface ChartProps {
    data: PowerRecord[],
    buckets: number;
    referenceValue: number;
    leader: PursuitResult[] | undefined;
}

/*
const CustomizedXTick = (props: any) => {
    const { x, y, payload, start } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} fill='#666'>
                <tspan textAnchor="middle" x="0">
                    {secondsToFormat((payload.value - start) / 1000, 'HH:mm:ss')}
                </tspan>
                <tspan textAnchor="middle" x="0" dy="20">
                    {(new Date(payload.value)).toLocaleTimeString('nl-BE')}
                </tspan>
            </text>
        </g>
    )
}
*/

const Chart = ({ leader, data, buckets, referenceValue }: ChartProps) => {
    const [refArea, setRefArea] = useState(initialLRConf);
    const [sampled, setSampled] = useState<PowerRecord[]>([]);
    const [chartConf, setChartConf] = useState(initialLRConf)
    const [chartProps, setChartProps] = useState<Partial<Record<DataIndices, boolean>>>({
        [DataIndices.POWER]: true,
        [DataIndices.PACE]: true,
        [DataIndices.SKIBA]: true,
        [DataIndices.WATERWORTH]: true,
        [DataIndices.FROCIONI]: true,
    });

    const safeSetRefArea = (e: any, direction: string) => {
        try {
            if (!e || (direction === 'right' && e.activeLabel < refArea.left)) {
                throw new Error();
            }

            setRefArea(prev => ({ ...prev, [direction]: e.activeLabel }));
        } catch (e) {
            setRefArea(initialLRConf);
        }
    }

    const zoom = () => {
        if (refArea.left === refArea.right || refArea.right === 0) {
            setRefArea(initialLRConf);
            return;
        }

        // RefArea are x-axis labels (epochMS timestamps). Find indices of these items
        setChartConf(prev => ({
            ...prev,
            left: data.findIndex(x => x.date === refArea.left),
            right: data.findIndex(x => x.date === refArea.right),
        }));
        setRefArea(initialLRConf);
    }

    const resetZoom = () => {
        setChartConf({ left: 0, right: data.length });
    }

    const selectLine = (e: any) => {
        setChartProps({
            ...chartProps,
            [e.dataKey]: !chartProps[e.dataKey as DataIndices],
        });
    };

    useEffect(() => {
        if (chartConf.right === 0) {
            setChartConf(prev => ({ ...prev, right: data.length }));
        }

        const lttbRes = LTTB(data.slice(chartConf.left, chartConf.right), buckets, DataIndices.DATE, DataIndices.POWER);
        setSampled(lttbRes)
    }, [data, chartConf.left, chartConf.right])

    useEffect(() => {
        resetZoom();
    }, [data])

    return (
        <ChartContainer>
            <ResponsiveContainer width='100%' height={500}>
                <LineChart 
                    data={sampled} 
                    height={300}
                    onMouseDown={(e: any) =>  safeSetRefArea(e, 'left')}
                    onMouseMove={(e: any) => refArea.left && safeSetRefArea(e, 'right')}
                    onMouseUp={() => zoom()}
                >
                    <CartesianGrid stroke='#eee' strokeDasharray='5 5' />
                    <XAxis 
                        dataKey='date'
                        tickFormatter={(value: number) => secondsToFormat((value - data[0].date) / 1000, 'HH:mm:ss')}
                        style={{ userSelect: 'none' }}
                        //tick={<CustomizedXTick start={startTime} />}
                    />
                    <YAxis 
                        yAxisId='1'
                        tickFormatter={(value: number) => `${value} W`}
                        style={{ userSelect: 'none' }}
                    />
                    <YAxis 
                        yAxisId='2'
                        orientation='right'
                        tickFormatter={(value: number) => `${value} J`}
                        style={{ userSelect: 'none' }}
                    />
                    <Tooltip labelFormatter={(epochMS) => `${secondsToFormat((epochMS - data[0].date) / 1000, 'HH:mm:ss')} (${epochToFormat(epochMS, 'HH:mm:ss', false)})`}/>
                    <Legend onClick={selectLine} style={{ userSelect: 'none' }} />
                    <ReferenceLine y={referenceValue} yAxisId='1' strokeDasharray='3 3' stroke='red' />
                    {lineConfigs.map((config, i) => (
                        <Line 
                            {...config}
                            key={i}
                            type='monotone' 
                            dot={false} 
                            hide={!chartProps[config.dataKey]} 
                        />
                    ))}
                    {(refArea.left && refArea.right) && (
                        <ReferenceArea
                            yAxisId='1'
                            x1={refArea.left}
                            x2={refArea.right}
                            strokeOpacity={0.3}
                            stroke='grey'
                        />
                    )}
                    {leader && leader.map((x, i) => {
                        const end_idx = x.end_idx < data.length ? x.end_idx : data.length - 1;

                        return (
                            <ReferenceArea
                                key={i}
                                yAxisId='1'
                                x1={data[x.start_idx].date}
                                x2={data[end_idx].date}
                                strokeOpacity={0.3}
                                fill="red"
                                fillOpacity={0.1}
                            />
                        );
                    })}
                </LineChart>
            </ResponsiveContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Button variant='outlined' sx={{ width: '50%'}} onClick={resetZoom}>Reset zoom</Button>
            </Box>
        </ChartContainer>
    );
}

export default Chart;