import { useEffect } from "react";
import Slider, { PipsMode, target as NoUiSliderTarget } from "nouislider";
import { PursuitResult } from '../../../types';
import './RiderSlider.css';
import "nouislider/dist/nouislider.css";
import { secondsToFormat } from "../../../helpers";
import moment from "moment";

/**
 * @param slider HtmlElement with an initialized slider
 * @param threshold Minimum proximity (in percentages) to merge tooltips
 * @param separator String joining tooltips
 */
 function mergeTooltips(slider: any, threshold: any, separator = '-') {

    var textIsRtl = getComputedStyle(slider).direction === 'rtl';
    var isRtl = slider.noUiSlider.options.direction === 'rtl';
    var isVertical = slider.noUiSlider.options.orientation === 'vertical';
    var tooltips = slider.noUiSlider.getTooltips();
    var origins = slider.noUiSlider.getOrigins();

    // Move tooltips into the origin element. The default stylesheet handles this.
    tooltips.forEach(function (tooltip: any, index: any) {
        if (tooltip) {
            origins[index].appendChild(tooltip);
        }
    });

    slider.noUiSlider.on('update', function (values: any, handle: any, unencoded: any, tap: any, positions: any) {
        var pools: any[][] = [[]];
        var poolPositions: any[][] = [[]];
        var poolValues: any[][] = [[]];
        var atPool = 0;

        // Assign the first tooltip to the first pool, if the tooltip is configured
        if (tooltips![0]) {
            pools[0][0] = 0;
            poolPositions[0][0] = positions[0];
            poolValues[0][0] = values[0];
        }

        for (var i = 1; i < positions.length; i++) {
            if (!tooltips[i] || (positions[i] - positions[i - 1]) > threshold) {
                atPool++;
                pools[atPool] = [];
                poolValues[atPool] = [];
                poolPositions[atPool] = [];
            }

            if (tooltips[i]) {
                pools[atPool].push(i);
                poolValues[atPool].push(values[i]);
                poolPositions[atPool].push(positions[i]);
            }
        }

        pools.forEach(function (pool, poolIndex) {
            var handlesInPool = pool.length;

            for (var j = 0; j < handlesInPool; j++) {
                var handleNumber = pool[j];

                if (j === handlesInPool - 1) {
                    var offset = 0;

                    poolPositions[poolIndex].forEach(function (value) {
                        offset += 1000 - value;
                    });

                    var direction = isVertical ? 'bottom' : 'right';
                    var last = isRtl ? 0 : handlesInPool - 1;
                    var lastOffset = 1000 - poolPositions[poolIndex][last];
                    offset = (textIsRtl && !isVertical ? 100 : 0) + (offset / handlesInPool) - lastOffset;

                    // Center this tooltip over the affected handles
                    tooltips[handleNumber].innerHTML = poolValues[poolIndex].join(separator);
                    tooltips[handleNumber].style.display = 'block';
                    tooltips[handleNumber].style[direction] = offset + '%';
                } else {
                    // Hide this tooltip
                    tooltips[handleNumber].style.display = 'none';
                }
            }
        });
    });
}

interface RiderSliderProps {
    pursuitAnalysis: PursuitResult[];
    raceDuration: number;
    setRiderDurationChange: React.Dispatch<React.SetStateAction<{ handle: number, values: number[] } | null>>;
}

const RiderSlider = ({ pursuitAnalysis, raceDuration, setRiderDurationChange }: RiderSliderProps) => {
    useEffect(() => {
        const slider: NoUiSliderTarget = document.getElementById('sliderTest')!;
        if (slider) {
            slider.noUiSlider?.destroy();
        }

        Slider.create(document.getElementById('sliderTest')!, {
            start: pursuitAnalysis.slice(0, -1).map(x => x.end_idx),
            connect: Array(pursuitAnalysis.length).fill(true),
            tooltips: Array(pursuitAnalysis.length).slice(0, -1).fill(true),
            range: { min: 0, max: raceDuration },
            pips: {
                mode: PipsMode.Count, 
                values: 10,
                format: {
                    to: (value: number) => secondsToFormat(value, 'mm:ss', true),
                },
            },
            step: 1,
            format: {
                from: Number,
                to: value => `${secondsToFormat(value, 'mm:ss', true)}`,
            }
        });

        const connect = slider.querySelectorAll('.noUi-connect');

        for (let i = 0; i < pursuitAnalysis.length; ++i) {
            const colorClass = `c-${pursuitAnalysis[i].rider_idx + 1}-color`;
            connect[i].classList.add(colorClass);
        }

        slider.noUiSlider?.on('change', (values, handle, uneconded, tap, positions, nslider) => {
            // values: Current slider values (array);
            // handle: Handle that caused the event (number);
            // unencoded: Slider values without formatting (array);
            // tap: Event was caused by the user tapping the slider (boolean);
            // positions: Left offset of the handles (array);
            // nslider: slider public Api (noUiSlider);

            // Dont call changeFunction here directly since it will use a cached version with outdated state.
            // Catching the event through an effect hook will prevent this.
            setRiderDurationChange({ handle, values: values.map(x => moment(x, 'mm:ss').diff(moment().startOf('day'), 'seconds')) });
        });

        mergeTooltips(slider, 5, ' - ');
    }, [pursuitAnalysis]);

    /*
    useEffect(() => {
        console.log('jkl;kljlkj;lj')
        const slider: NoUiSliderTarget = document.getElementById('sliderTest')!;
        slider.noUiSlider?.set(pursuitAnalysis.slice(0, -1).map(x => x.end_idx));
    }, [pursuitAnalysis]);
    */

    return (
        <div id='sliderTest' style={{ width: '100%', marginTop: 10 }}></div>
    )
}

export default RiderSlider;