import { Grid, Typography, ToggleButton, styled, TextField, Box, Button } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { changeSimulationProp, selectChartBuckets, selectSimulationConfig, setChartBuckets } from "../../features/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RiderInfoWithFile, RiderSwitch } from "../../types";
import UploadOptions from "../upload/UploadOptions";

const StyledTextField = styled(TextField)({
    marginRight: 2,
    marginLeft: 2,
});

interface SimulationSettingsProps {
    handleLoadRace: (raceInfo: RiderInfoWithFile[], riderSwitches: RiderSwitch[], raceName: string) => void,
    handleUpdateOriginalSwitches: (serializedSwitches: string) => void,
    setRaceFiles: React.Dispatch<React.SetStateAction<string>>,
    setRaceName: React.Dispatch<React.SetStateAction<string>>,
    setRace: React.Dispatch<React.SetStateAction<RiderInfoWithFile[]>>,
    switchesSerialized: string,
}

const SimulationSettings = ({ handleLoadRace, handleUpdateOriginalSwitches, setRace, setRaceFiles, setRaceName, switchesSerialized }: SimulationSettingsProps) => {
    const dispatch = useAppDispatch();
    const changeSimulationConfig = (name: string, value: number) => dispatch(changeSimulationProp({ option: name, value }));

    const simulationConfig = useAppSelector(selectSimulationConfig);
    const chartBuckets = useAppSelector(selectChartBuckets);

    const [switchesText, setSwitchesText] = useState('');

    useEffect(() => {
        setSwitchesText(switchesSerialized)
    }, [switchesSerialized])

    return (
        <Grid>
            <Grid item lg={12} md={12}>
                <Typography variant='h4'>Simulation settings</Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} sx={{ paddingBottom: 3, columnGap: 1 }} display='flex' flexDirection='row'>
                <Grid item lg={10} md={10} sm={12}>
                    <UploadOptions handleLoadRace={handleLoadRace} setRace={setRace} setRaceFiles={setRaceFiles} setRaceName={setRaceName} />
                </Grid>
                <Grid item lg={2} md={2} sm={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                        <TextField label='Time (int), Rider stopped (bool)' multiline value={switchesText} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSwitchesText(e.target.value)}/>
                        <Button variant='outlined' onClick={() => handleUpdateOriginalSwitches(switchesText)}>Update switches</Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid item container lg={12} md={12} columnGap={0.5}>
                <StyledTextField
                    name='buckets'
                    label='Amount of buckets'
                    type='number'
                    value={chartBuckets}
                    onChange={(e) => dispatch(setChartBuckets(Number(e.target.value)))}
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        label='Start time offset'
                        format='HH:mm:ss'
                        value={moment().startOf('day').milliseconds(simulationConfig.startOffset)}
                        ampm={false}
                        onChange={(newValue) => {
                            const offset = newValue ? newValue.diff(moment().startOf('day'), 'seconds') : 0
                            changeSimulationConfig('startOffset', offset * 1000); // in milliseconds
                        }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        label='Race duration'
                        format='mm:ss'
                        value={moment().startOf('day').seconds(simulationConfig.minRaceDuration)}
                        ampm={false}
                        onChange={(newValue) => {
                            const offset = newValue ? newValue.diff(moment().startOf('day'), 'seconds') : 0
                            changeSimulationConfig('minRaceDuration', offset); // in seconds
                        }}
                        disabled={Boolean(simulationConfig.estimateDuration)}
                    />
                </LocalizationProvider>
                <ToggleButton
                    color='primary'
                    value="check"
                    selected={Boolean(simulationConfig.estimateDuration)}
                    onChange={() => changeSimulationConfig('estimateDuration', Number(!Boolean(simulationConfig.estimateDuration)))}
                >
                    Estimate duration
                </ToggleButton>
            </Grid>
        </Grid>
    );
}

export default SimulationSettings;