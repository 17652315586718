import { DataIndices } from "../../types";

export const lineConfigs = [
    {
        yAxisId: '1',
        dataKey: DataIndices.POWER, 
        stroke: '#8884d8', 
    },
    {
        yAxisId: '2',
        dataKey: DataIndices.PACE, 
        stroke: '#880808',
    },
    {
        yAxisId: '2',
        dataKey: DataIndices.SKIBA, 
        stroke: '#82ca9d',
    },
    {
        yAxisId: '2',
        dataKey: DataIndices.FROCIONI, 
        stroke: '#0000FF',
    },
    {
        yAxisId: '2',
        dataKey: DataIndices.WATERWORTH, 
        stroke: '#ff8c00',
    },
];