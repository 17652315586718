import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { RootState } from "../store";
import { PowerRecord, RawPowerRecord, RiderInfo, RiderInfoWithFile } from "../types";

interface RaceState {
    rawData: RawPowerRecord[][],
    data: PowerRecord[][],
    race: RiderInfo[],
    name: string,
    files: string,
}

const initialState: RaceState = {
    rawData: [],
    data: [],
    race: [],
    name: '',
    files: '',
};

export const raceSlice = createSlice({
    initialState,
    name: 'race',
    reducers: {
        // Do not put files (non serializable entities in the store).
        initRace: (state, action: PayloadAction<{ raceInfo: RiderInfo[], name: string, files: string }>) => ({
            ...state,
            race: action.payload.raceInfo,
            name: action.payload.name,
            files: action.payload.files,
        }),
        changeData: (state, action: PayloadAction<PowerRecord[][]>) => ({
            ...state,
            data: action.payload,
        }),
        appendData: (state, action: PayloadAction<PowerRecord[]>) => ({
            ...state,
            data: [...state.data, action.payload],
        }),
        changeRiderData: (state, action: PayloadAction<{ index: number, data: PowerRecord[] }>) => ({
            ...state,
            data: state.data.map((x, i) => (i === action.payload.index ? action.payload.data : x)),
        }),
        changeRiderConfig: (state, action: PayloadAction<{ index: number, name: string, value: number }>) => ({
            ...state,
            race: state.race.map((x, i) => ({
                ...(action.payload.index === i ? { 
                    ...state.race[i], 
                    [action.payload.name]: action.payload.value,
                } : x)
            })),
        }),
    },
});

export const { changeData, appendData, changeRiderData } = raceSlice.actions;

export const selectData = (state: RootState) => state.race.data;
/*
export const selectRace = (state: RootState) => state.race.race;
export const selectRaceName = (state: RootState) => state.race.name;
export const selectRaceFiles = (state: RootState) => state.race.files;
*/

export default raceSlice.reducer;