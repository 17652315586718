import { ToastContainer } from 'react-toastify';
import './App.css';
import Home from './pages/Home';
import Layout from './pages/Layout';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Layout>
      <Home />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        pauseOnFocusLoss
        pauseOnHover
        />
    </Layout>
  );
}

export default App;
