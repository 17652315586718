import { TP_WR_SECONDS } from '../constants';
import { defined } from '../helpers';
import { RawPowerRecord, SimulationConfig } from '../types';

function findExcerciseWindow(data: any[], minRaceDuration: number, grow: boolean) {
    let startIdx = 0;
    let windowSum = data.slice(0, minRaceDuration).reduce((a, b) => a + (b.pwr || 0), 0);

    for (let i = minRaceDuration; i < data.length; ++i) {
        const newSum = windowSum - (data[i - minRaceDuration].pwr || 0) + (data[i].pwr || 0);

        if (newSum > windowSum) {
            startIdx = i - minRaceDuration;
            windowSum = newSum;
        }
    }

    let endIdx = startIdx + minRaceDuration;

    if (grow) {
        const windowLength = 10;
        const windowAverageHM = windowLength / data.slice(endIdx - windowLength, endIdx).reduce((a, b) => a + (defined(b.pwr) && b.pwr !== 0 ? 1 / b.pwr : 0), 0);

        /*
        Other stats options:

        const windowAverage = data.slice(endIdx - windowLength, endIdx).reduce((a, b) => a + (b.pwr || 0), 0) / windowLength;

        const l = data.slice(endIdx - 20, endIdx).sort((a, b) => a.pwr - b.pwr);
        const half = Math.floor(l.length / 2);
        const median = (l.length % 2) ? l[half].pwr : (l[half - 1].pwr + l[half].pwr) / 2.0;
        */

        while (endIdx < data.length)  {
            const updatedAvg = data.slice(endIdx - windowLength, endIdx).reduce((a, b) => a + (b.pwr || 0), 0) / windowLength;

            if (updatedAvg < 0.85 * windowAverageHM) {
                break;
            } 

            ++endIdx;
        }
    }

    return { startIdx, endIdx };
}

export function preflightSim(data: RawPowerRecord[], simulationConfig: SimulationConfig) {
    if (data.length === 0) {
        return { res: data, windowDuration: -1 };
    }

    const { startOffset, minRaceDuration, estimateDuration } = simulationConfig;
    if (startOffset !== 0) {
        const threshold = data[0].date + startOffset;
        data = data.slice(data.findIndex(x => x.date >= threshold));
    }

    let windowDuration = data.length;
    if (minRaceDuration !== 0 || estimateDuration) {
        const { startIdx, endIdx } = findExcerciseWindow(data, estimateDuration ? TP_WR_SECONDS : minRaceDuration, estimateDuration);
        data = data.slice(startIdx, endIdx);
        windowDuration = endIdx - startIdx;
    }

    return { res: data, windowDuration };
}