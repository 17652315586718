import { Box, Grid, styled, TextField, Typography } from "@mui/material";
import { secondsToFormat } from "../../helpers";
import { PowerRecord, PursuitResult, RiderInfo } from "../../types";
import Chart from "../chart/Chart";
import NumberTextField from "../shared/NumberTextField";
import './riderSlider/RiderSlider.css';

interface RiderViewProps {
    riderIdx: number;
    rider: RiderInfo;
    riderData: PowerRecord[];
    bucketSize: number;
    windowDuration: number;
    changeRiderConfig: (i: number, field: string, value: number) => void;
    leader: PursuitResult[] | undefined;
    errorMap: { [key: string]: boolean };
    setError: CallableFunction;
}

const StyledTextField = styled(TextField)({
    marginRight: 2,
    marginLeft: 2,
})

const RiderView = ({ leader, riderIdx, rider, riderData, bucketSize, windowDuration, changeRiderConfig, errorMap, setError }: RiderViewProps) => {
    const windowDurationText = windowDuration !== -1 ? secondsToFormat(windowDuration, 'mm:ss') : 'unknown';

    if (!riderData) return null;

    return (
        <Grid item lg={12} md={12}>
            <Typography className={leader ? `c-${riderIdx + 1}-color` : undefined} textAlign='center' variant='h6'>{`Rider ${riderIdx + 1}: ${rider.name} (window duration: ${windowDurationText})`}</Typography>
            <Chart 
                data={riderData} 
                buckets={bucketSize} 
                referenceValue={rider.cp} 
                leader={((leader) ? leader.filter(x => x.rider_idx === riderIdx) : undefined)}
            />
            <Grid item lg={12} md={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                    <StyledTextField 
                        name='cp' 
                        label='Critical power (W)' 
                        type='number' 
                        value={rider.cp} 
                        onChange={(e) => changeRiderConfig(riderIdx, e.target.name, Number(e.target.value))}
                    />
                    <StyledTextField 
                        name='wprime'
                        label='WPrime (J)' 
                        type='number' 
                        value={rider.wprime}
                        onChange={(e) => changeRiderConfig(riderIdx, e.target.name, Number(e.target.value))}
                    />
                    <NumberTextField 
                        id='weight' 
                        label='Weight (kg)'
                        value={rider.weight}
                        onNewValue={(e: number) => changeRiderConfig(riderIdx, 'weight', e)} 
                        errorMap={errorMap} 
                        setErrorMap={setError}                        
                        style={{ marginLeft: '2px', marginRight: 2 }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export default RiderView;