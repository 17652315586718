import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Dropbox } from "dropbox";
import { useEffect, useState } from "react";
import { RaceConfig, RiderInfoWithFile, RiderSwitch } from "../../types";

interface DropboxHandlerProps {
    onLoadRace: (raceInfo: RiderInfoWithFile[], riderSwitches: RiderSwitch[], raceName: string) => void;
}

const DropboxHandler = ({ onLoadRace }: DropboxHandlerProps) => {
    const [races, setRaces] = useState<string[]>([]);
    const [race, setRace] = useState<string>('');

    const handleRaceChange = (event: SelectChangeEvent) => setRace(event.target.value);

    // https://www.codemzy.com/blog/dropbox-long-lived-access-refresh-token
    const appKey = 'dzvg6zqejz8tqxd';
    const appSecret = '1j4vaevuococbn5';
    const refreshToken = 'lNEgpfvHd5kAAAAAAAAAAXE6os4jSdQPeHKtQvP68i0h9rV3uU1uV-6TPv3ZB5lZ';
    const rootPath = '/PACE/Races';
    const dbx = new Dropbox({ clientId: appKey, clientSecret: appSecret, refreshToken });

    useEffect(() => {
        dbx.filesListFolder({ path: rootPath  }).then(response => {
            setRaces(response.result.entries.filter(x => x[".tag"] === 'folder').map(x => x.name));
        }).catch((err) => console.log(err))
    }, []);

    useEffect(() => {
        if (!race) {
            return;
        }

        (async () => {
            const racePath = `${rootPath}/${race}`;

            const racesContent = await dbx.filesListFolder({ path: racePath });

            const configFiles = racesContent.result.entries
                .filter(x => x[".tag"] === 'file' && x.name.toLowerCase() === 'config.json')
                .map(x => x.name);
            const configResponse = await dbx.filesDownload({ path: `${racePath}/config.json`})
            const config: RaceConfig = JSON.parse(await (configResponse.result as any).fileBlob.text());

            const fitFileNames = racesContent.result.entries
                .filter(x => x[".tag"] === 'file' && x.name.toLowerCase().split('.').pop() === 'fit')
                .map(x => x.name);

            if (configFiles.length !== 1 || config.riders.length !== fitFileNames.length) {
                return;
            } 

            const configWithFiles: RiderInfoWithFile[] = await Promise.all(config.riders.map(async (x) => {
                const fileResponse = await dbx.filesDownload({ path: `${racePath}/${x.filename}` });
                const blob = (fileResponse.result as any).fileBlob;

                return { ...x, file: new File([blob], x.filename) };
            }));

            onLoadRace(configWithFiles, config.pursuit, race);
        })();
    }, [race])

    return (
        <FormControl fullWidth>
            <InputLabel id='select-race-label'>Race</InputLabel>
            <Select
                id='select=race'
                labelId='select-race-label'
                label='Race'
                value={race}
                onChange={handleRaceChange}
                defaultValue={''}
            >
                {races.map((r, i) => <MenuItem key={i} value={r}>{r}</MenuItem>)}
            </Select> 
        </FormControl>
    );

}

export default DropboxHandler;