import { Button } from "@mui/material";
import { selectData } from "../../features/raceSlice";
import { jsonToCsv } from "../../helpers";
import { useAppSelector } from "../../hooks/redux";

interface ExportOptionsProps {
    raceName: string,
    findLeadingRider: (index: number) => number | undefined;
}

const ExportOptions = ({ raceName, findLeadingRider }: ExportOptionsProps) => {
    const data = useAppSelector(selectData);

    const transformDownload = () => {
        if (data.length == 0) {
            return [];
        }

        const dataPrefixed = data.map((x, i) => x.map(riderData => Object.fromEntries(
            Object.entries(riderData).map(([k, v]) => [`rider_${i}_${k}`, v])
        )));

        const lengths = dataPrefixed.map(x => x.length);
        const longest_id = lengths.indexOf(Math.max(...lengths));

        return dataPrefixed[longest_id].map((obj, index) => ({
            ...obj,
            ...dataPrefixed
                .filter((_, j) => j != longest_id)
                .map(x => index < x.length ? x[index] : {})
                .reduce((acc, cv) => ({ ...acc, ...cv }), {}),
            leader: findLeadingRider(index),
        }));
    }

    return (
        <Button
            href={`data:text/csv;charset=utf-8, ${encodeURIComponent(jsonToCsv(transformDownload()))}`}
            download={`${raceName}.csv`}
            variant='outlined'
            disabled={data.length === 0}
        >
            Download results
        </Button>
    );
}

export default ExportOptions;