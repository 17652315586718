import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return 'https://pace.sportsdatascience.be/api';
    case 'development':
      return 'http://localhost:5000/api';
  }
}

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl(),
});

export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: _ => ({}),
});