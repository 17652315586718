import { styled } from "@mui/material";
import { useEffect } from "react"
import { useDropzone } from "react-dropzone"
import DropboxHandler from "./DropboxHandler";
import GDrive from "./GDrive";

interface FitDropzoneProps {
    onFile: (f: File[]) => void,
}

const Container = styled('div')({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#808080',
    borderStyle: 'dashed',
    color: '#808080',
    outline: 'none',
});

const FitDropzone = ({ onFile }: FitDropzoneProps) => {
    const { acceptedFiles, getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({ 
        accept: {
            'application/octet-stream': ['.fit', '.FIT'],
        },
    });

    useEffect(() => {
        onFile(acceptedFiles);
    }, [acceptedFiles])

    return (
        <div>
            {acceptedFiles.length === 1 && (
                <p>{`Selected file: ${acceptedFiles[0].name} (${acceptedFiles[0].size} bytes)`}</p>
            )}
            <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                <input {...getInputProps()} />
                <p>Drag and drop some files here, or click to select files</p>
                {/*<GDrive onFile={onFile} />*/}
            </Container>
        </div> 
    );
}

export default FitDropzone;