export const defined = (x: any) => typeof x !== 'undefined';

export const jsonToCsv = (x: any[]) => {
    if (!defined(x) || x.length === 0) {
        return '';
    }

    const replacer = (key: string, value: any) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(x[0])
    const csv = [
        header.join(','), // header row first
        ...x.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n');

    return csv;
}