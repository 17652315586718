export enum DataIndices {
    DATE = 'date',
    POWER = 'pwr',
    CADANCE = 'cad',
    SKIBA = 'skiba',
    SKIBA2 = 'skiba2',
    BARTRAM = 'bartram',
    WATERWORTH = 'waterworth',
    FROCIONI = 'froncioni-skiba-clarke',
    PACE = 'pace'
}

export type RiderInfo = {
    name: string;
    filename: string;
    cp: number;
    wprime: number;
    weight: number;
}

export type RiderInfoWithFile = RiderInfo & {
    file: File;
}

export type RaceConfig = {
    riders: RiderInfo[];
    pursuit: RiderSwitch[];
}

export type RiderSwitch = {
    time: number,
    stopped: boolean,
}

export type RawPowerRecord = {
    date: number;
    pwr: number | undefined;
    cad: number | undefined;
}

export type SimulationInput = {
    index: number;
    data: RawPowerRecord[];
    simulationConfig: SimulationConfigInput;
}

export type PowerRecord = {
    date: number;
    pwr: number;
    skiba: number;
    waterworth: number;
    'froncioni-skiba-clarke': number;
    pace: number;
};

export type SimulationConfig = {
    startOffset: number;
    minRaceDuration: number;
    estimateDuration: boolean;
}

export type SimulationConfigInput = {
    cp: number;
    wprime: number;
    weight: number;
    skiba: boolean;
    skiba2: boolean;
    bartram: boolean;
    pace: boolean;
}

export type BatteryRecord = {
    pwr: number;
    wpbal: number;
    date: number;
}

export type FitRecord = {
    type: string;
    data: FitData;
}

export type FitData = {
    timestamp: string;
    cadence?: number;
    power?: number;
}

export type PursuitInput = {
    riders: {
        weight: number;
        data: RawPowerRecord[];
    }[],
}

export type PursuitResult = {
    rider_idx: number;
    start_idx: number;
    end_idx: number;
    order: number[];
}

export type PursuitPositionsPwr = {
    start: number[];
    inRace: { avg: number, std: number }[][];
}