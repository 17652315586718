import { Grid } from "@mui/material";
import React from "react";
import { RiderInfoWithFile, RiderSwitch } from "../../types";
import DropboxHandler from "./DropboxHandler";
import FitDropzone from "./FitDropzone";

interface UploadOptionsProps {
    handleLoadRace: (raceInfo: RiderInfoWithFile[], riderSwitches: RiderSwitch[], raceName: string) => void,
    setRaceFiles: React.Dispatch<React.SetStateAction<string>>,
    setRaceName: React.Dispatch<React.SetStateAction<string>>,
    setRace: React.Dispatch<React.SetStateAction<RiderInfoWithFile[]>>,
}
const UploadOptions = ({ handleLoadRace, setRaceFiles, setRaceName, setRace }: UploadOptionsProps) => (
    <Grid rowGap={2} container>
        <Grid item lg={12}>
            <DropboxHandler onLoadRace={handleLoadRace} />
        </Grid>
        <Grid item lg={12}>
            <FitDropzone onFile={(files: File[]) => {
                setRaceFiles(files.map(f => f.name).join(','));
                setRace(files.map(f => ({
                    file: f,
                    name: 'Unknown',
                    filename: f.name,
                    cp: 250,
                    wprime: 18000,
                    weight: 75,

                })))
                setRaceName('NO RACE');
            }} />
        </Grid>
    </Grid>
)

export default UploadOptions;