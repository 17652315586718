import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import RiderSlider from '../components/rider/riderSlider/RiderSlider';
import RiderView from '../components/rider/RiderView';
import ExportOptions from '../components/simulation/ExportOptions';
import SimulationSettings from '../components/simulation/SimulationSettings';
import { useSimulateMutation } from '../features/api/simulationSlice';
import { appendData, changeData, changeRiderData, selectData } from '../features/raceSlice';
import { selectSimulationConfig, selectChartBuckets } from '../features/settingsSlice';
import { fitToJson, rotateLeft } from '../helpers';
import { usePrevious } from '../hooks';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { preflightSim } from '../simulations';
import { PowerRecord, PursuitPositionsPwr, PursuitResult, RawPowerRecord, RiderInfoWithFile, RiderSwitch } from '../types';

const Home = () => {
    const simulationConfig = useAppSelector(selectSimulationConfig);
    const chartBuckets = useAppSelector(selectChartBuckets);
    const data = useAppSelector(selectData);

    const dispatch = useAppDispatch();
    const setData = (d: PowerRecord[][]) => dispatch(changeData(d));

    const [rawData, setRawData] = useState<RawPowerRecord[][]>([]);
    const [detectedWindow, setDetectedWindow] = useState<number[]>([]);
    const [errorMap, setErrorMap] = useState<{ [key: string]: boolean }[]>([]);
    const [race, setRace] = useState<RiderInfoWithFile[]>([]);
    const [raceFiles, setRaceFiles] = useState<string>('');
    const [raceName, setRaceName] = useState<string>('');
    const [originalSwitches, setOriginalSwitches] = useState<RiderSwitch[]>([]);

    const [pursuitAnalysis, setPursuitAnalysis] = useState<PursuitResult[] | undefined>();
    const [pursuitLookup, setPursuitLookup] = useState<PursuitPositionsPwr | undefined>();
    const [riderDurationChange, setRiderDurationChange] = useState<{ handle: number, values: number[]} | null>(null);
    const [switchesText, setSwitchesText] = useState('');

    const previousRace = usePrevious(race);

    const [startSimulation, { isLoading: isLoadingSimulation }] = useSimulateMutation();

    const changeRiderConfig = (index: number, name: string, value: number) => setRace(prev => prev.map((x, i) => ({
        ...(index === i ? { ...prev[i], [name]: value } : x)
    })));

    const buildSimulationConfig = (index: number) => ({
        cp: race[index].cp,
        wprime: race[index].wprime,
        weight: race[index].weight,
        skiba: true,
        skiba2: true,
        bartram: true,
        pace: true,
    });

    const triggerSimulation = async (indices: number[], firstRun: boolean) => {
        for (const idx of indices) {
            if (!Object.values(errorMap[idx]).every(x => !x)) {
                return;
            }

            let d: RawPowerRecord[] = [];
            if (firstRun) {
                // If res contains no data, windowduration === -1
                const { res, windowDuration } = preflightSim(rawData[idx], simulationConfig);
                d = res;

                setDetectedWindow(prev => {
                    if (idx < prev.length) {
                        return prev.map((x, i) => i === idx ? windowDuration : x);
                    } else {
                        return [...prev, windowDuration];
                    }
                });
            } else {
                d = data[idx].map(x => ({ date: x.date, pwr: x.pwr, cad: undefined }));
            }

            const simRes = await startSimulation({ index: idx, data: d, simulationConfig: buildSimulationConfig(idx) }).unwrap();
            if (idx < data.length) {
                dispatch(changeRiderData({ index: idx, data: simRes }));
                //setData(data.map((x, i) => i === idx ? simRes : x))
            } else {
                dispatch(appendData(simRes));
            }
        }
    };

    const findLeadingRider = (id: number) => pursuitAnalysis?.filter(x => x.start_idx <= id && id <= x.end_idx)[0].rider_idx;

    const handleLoadRace = (raceInfo: RiderInfoWithFile[], pursuitSwitches: RiderSwitch[], raceName: string) => {
        setRaceFiles(raceInfo.map(x => x.filename).join(','));
        setRace(raceInfo);
        setRaceName(raceName);
        setOriginalSwitches(pursuitSwitches);
        
        if (pursuitSwitches) {
            setSwitchesText(pursuitSwitches.map(x => `${x.time},${x.stopped}`).join('\n'))
        } else {
            setSwitchesText('');
        }
    }

    const pursuit = () => {
        const order = Array.from(Array(race.length).keys());
        const mappedSwitches: PursuitResult[] = [];

        for (const [i, riderSwitch] of [...originalSwitches, { time: Math.max(...data.map(x => x.length)), stopped: false }].entries()) {
            const id = order[(i + race.length - order.length) % order.length];

            let orderCopy = [...order];
            for (let j = 0; j < order.indexOf(id); ++j) {
                orderCopy = rotateLeft(orderCopy);
            }

            mappedSwitches.push({
                rider_idx: id,
                start_idx: mappedSwitches.length === 0 ? 0 : mappedSwitches[mappedSwitches.length - 1].end_idx + 1,
                end_idx: riderSwitch.time,
                order: orderCopy,
            });

            if (riderSwitch.stopped) {
                const removeIndex = order.indexOf(id);
                if (removeIndex > -1) {
                    order.splice(removeIndex, 1);
                }
            }
        }

        const startingPwr = data.map(x => {
            const part = x.slice(mappedSwitches[0].start_idx, mappedSwitches[0].end_idx) 
            return Math.round(part.reduce((a, b) => a + b.pwr, 0) / part.length)
        });

        const powerGrid: number[][][] = new Array();
        for (let i = 0; i < 4; ++i) {
            powerGrid[i] = new Array();
            for (let j = 0; j < 4; ++j) {
                powerGrid[i][j] = new Array();
            }
        }

        for (let i = 1; i < mappedSwitches.length; ++i) {
            const { start_idx, end_idx, order } = mappedSwitches[i];
            for (let position = 0; position < order.length; ++position) {
                const riderId = order[position];
                powerGrid[riderId][position].push(...data[riderId].slice(start_idx, end_idx).map(x => x.pwr));
            }
        }

        const averagedPowerGrid = powerGrid.map(x => x.map(y => {
            const positionAverage = Math.round(y.reduce((a, b) => a + b, 0) / y.length);
            const positionDeviation = Math.sqrt((y.reduce((a, b) => a + Math.pow(b - positionAverage, 2), 0)) / y.length);

            return { avg: positionAverage, std: positionDeviation };
        }));

        setPursuitAnalysis(mappedSwitches);
        setPursuitLookup({ start: startingPwr, inRace: averagedPowerGrid });
    }

    const onOriginalSwitchesUpdate = (serializedSwitches: string) => {
        const lines = serializedSwitches.split('\n')
        const updatedSwitches = lines.map(x => {
            const parts = x.split(',');
            if (parts.length !== 2 || isNaN(+parts[0]) || (parts[1] !== 'false' && parts[1] !== 'true')) {
                return { time: -1, stopped: false } as RiderSwitch;
            }

            return {
                time: +parts[0],
                stopped: parts[1] === 'true',
            } as RiderSwitch
        })
        
        if (updatedSwitches.every(x => x.time !== -1)) {
            setOriginalSwitches(updatedSwitches);
            toast.success('Parsing successful, please rerun the leader analysis!')
        } else {
            setSwitchesText(originalSwitches.map(x => `${x.time},${x.stopped}`).join('\n'))
            toast.error('Parsing error, values reset to original state', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }

    useEffect(() => {
        if (pursuitAnalysis) {
            setPursuitAnalysis(undefined);
        }

        (async () => {
            setRawData(await Promise.all(race.map(x => fitToJson(x.file))));
        })();

        setErrorMap(Array.from(Array(race.length).keys()).map(_ => ({})));
    }, [race.length, raceFiles])

    useEffect(() => {
        if (rawData.length == race.length) {
            triggerSimulation(Array.from(Array(race.length).keys()), true);
        }
    }, [rawData])

    useEffect(() => {
        if (rawData.length > 0) {
            if (pursuitAnalysis) {
                setPursuitAnalysis(undefined);
            }

            const indices = Array.from(Array(race.length).keys());
            const timeout = window.setTimeout(() => triggerSimulation(indices, true), 2000);
            return () => window.clearTimeout(timeout);
        }
    }, [simulationConfig]);

    useEffect(() => {
        const timeout = window.setTimeout(() => {
            if (!previousRace || previousRace.length === 0) {
                return;
            }

            const indices = race.map((item, i) => ({ item, i }))
                .filter(x => !(x.item.cp === previousRace![x.i].cp
                    && x.item.wprime === previousRace![x.i].wprime
                    && x.item.weight === previousRace![x.i].weight))
                .map(x => x.i);

            triggerSimulation(indices, typeof previousRace === 'undefined');
        }, 2000);

        return () => window.clearTimeout(timeout);
    }, [race])

    useEffect(() => {
        if (pursuitAnalysis && pursuitLookup) {
            (async () => {
                setData(await Promise.all(data.map(async (riderRecords, riderId) => {
                    // Power transformation scheme
                    const segments: number[] = pursuitAnalysis.slice(1).map(interval => {
                        const { start_idx, end_idx, order } = interval;
                        const stdNormalizationFactor = 1;

                        const position = order.indexOf(riderId);
                        if (position === -1) {
                            // Fill with zeroes after death pull
                            return new Array(end_idx - start_idx + 1).fill(0);
                        }

                        const { avg, std } = pursuitLookup.inRace[riderId][position];
                        const normalizedStd = std / stdNormalizationFactor;
                        const fillValue = order.indexOf(riderId) === -1 ? 0 : avg;

                        return new Array(end_idx - start_idx + 1)
                            .fill(fillValue)
                            .map(x => x + Math.floor(Math.random() * (normalizedStd + (normalizedStd) - (normalizedStd))));
                    }).reduce((acc, val) => acc.concat(val), Array(pursuitAnalysis[0].end_idx - pursuitAnalysis[0].start_idx).fill(pursuitLookup.start[riderId]));

                    return await startSimulation({
                        index: riderId,
                        data: riderRecords.map((x, i) => ({ 
                            date: x.date,
                            pwr: (i < segments.length) ? segments[i] : segments[segments.length - 1],
                            cad: undefined,
                        })) as RawPowerRecord[],
                        simulationConfig: buildSimulationConfig(riderId),
                    }).unwrap();
                })));
            })();
        }
    }, [pursuitAnalysis]);

    useEffect(() => {
        if (pursuitAnalysis) {
            // Handles are placed on the end_idx of each PursuitResult. There is a
            // handle for each PursuitResult except the last one which means that 
            // handle + 1 will never be out of bounds.
            const { handle, values } = riderDurationChange!;

            const left = pursuitAnalysis[handle];
            const leftData = [...data[left.rider_idx]]

            const right = pursuitAnalysis[handle + 1];
            const rightData = [...data[right.rider_idx]]

            const diff = left.end_idx - values[handle];
            for (let i = 0; i < Math.abs(diff); ++i) {
                const pointerLeft = left.end_idx + i * (diff < 0 ? 1 : -1);
                leftData[pointerLeft] = { 
                    ...leftData[pointerLeft], 
                    pwr: (diff < 0 ? leftData[left.start_idx] : leftData[left.end_idx + 1]).pwr,
                };

                const pointerRight = right.start_idx + i * (diff < 0 ? 1 : -1);
                rightData[pointerRight] = { 
                    ...rightData[pointerRight],
                    pwr: (diff < 0 ? rightData[right.start_idx - 1] : rightData[Math.min(right.end_idx, rightData.length - 1)]).pwr, 
                };
            }

            const updatedPursuitAnalysis = [...pursuitAnalysis];
            updatedPursuitAnalysis.splice(handle, 2, ...[{ ...left, end_idx: left.end_idx - diff }, { ...right, start_idx: right.start_idx - diff }]);

            setPursuitAnalysis(updatedPursuitAnalysis);
        }
    }, [riderDurationChange])

    return (
        <Grid container rowSpacing={5}>
            <Grid item lg={12} md={12}>
                <Typography variant='h4'>Simulation results</Typography>
            </Grid>
            { (race && data) && data.map((d, i) => (
                <Grid key={i} item lg={6} md={12}>
                    <RiderView
                        bucketSize={chartBuckets}
                        riderIdx={i}
                        rider={{ ...race[i] }}
                        riderData={d}
                        windowDuration={detectedWindow[i]}
                        changeRiderConfig={changeRiderConfig}
                        leader={pursuitAnalysis}
                        errorMap={errorMap[i]}
                        setError={(obj: { [key: string]: boolean }) => {
                            setErrorMap(prev => prev.map((x, j) => (i === j) ? obj : x))
                        }}
                    />
                </Grid>
            ))}
            {pursuitAnalysis && 
                <Grid item lg={12} md={12} sm={12} sx={{padding: 10}}>
                    <RiderSlider 
                        pursuitAnalysis={pursuitAnalysis}
                        raceDuration={Math.max(...detectedWindow)}
                        setRiderDurationChange={setRiderDurationChange}
                    /> 
                </Grid>
            }
            <Grid item lg={12} md={12} sm={12}>
                <SimulationSettings
                    handleLoadRace={handleLoadRace}  
                    setRace={setRace}
                    setRaceFiles={setRaceFiles}
                    setRaceName={setRaceName}
                    switchesSerialized={switchesText}
                    handleUpdateOriginalSwitches={onOriginalSwitchesUpdate}
                />
            </Grid>
            <Grid item container lg={12} md={12}>
                <Grid item container lg={12} md={12} columnGap={0.5}>
                    <Button
                        onClick={pursuit}
                        variant='outlined'
                        disabled={data.length === 0 || data.length !== 4}
                    >
                        Pursuit leader analysis
                    </Button>
                    <ExportOptions findLeadingRider={findLeadingRider} raceName={raceName} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Home;